// generic inline error messages
export const INLINE_ERROR_MSGS = {
  TITLE: 'You must provide a title',
  DESCRIPTION: 'You must provide a description',
  TIME: 'You must select a time',
  DATE: 'You must select or enter a date as DD/MM/YYYY',
  DATE_AND_TIME: 'You must select a date and time',
  DATE_AND_TIME_START: 'You must select a start date and time',
  DATE_AND_TIME_START_BEFORE_CURRENT:
    'The start date & time must be after the current date & time',
  DATE_AND_TIME_END_BEFORE_CURRENT:
    'The end date & time must be after the current date & time',
  DATE_AND_TIME_SCHEDULED_BEFORE_CURRENT:
    'The scheduled date & time must be after the current date & time',
  DATE_AND_TIME_END_BEFORE_START:
    'The end date & time must be after the start date & time',
  DATE_AND_TIME_START_AND_END: 'You must select start and end dates and times',
  USER_GROUP: 'Add at least one user group',
};
