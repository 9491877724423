import { InlineExternalLink } from '@monash/portal-react';

export const UPDATES_EMPTY_VALUES = {
  title: '',
  description: '',
  links: [],
  startDate: null,
  startTime: '',
  endDate: null,
  endTime: '',
  publishDate: null,
  publishTime: null,
  userGroups: [],
};

export const SUBMIT_TYPES = {
  DRAFT: 'DRAFT',
  SAVE: 'SAVE',
  SCHEDULED: 'SCHEDULED',
  REMOVE_SCHEDULED: 'REMOVE_SCHEDULED',
  PUBLISH: 'PUBLISH',
};

export const DELETE_TYPES = {
  ARCHIVE: 'ARCHIVE',
  DELETE: 'DELETE',
};

// server error messages (5xx)
export const UPDATES_SERVER_ERROR_MSGS = {
  DRAFT: "We're having trouble saving the update right now - please try again.",
  PUBLISH:
    "We're having trouble publishing the update right now - please try again.",
  ARCHIVE:
    "We're having trouble archiving the update right now - please try again.",
  DELETE:
    "We're having trouble deleting the update right now - please try again.",
};

// client error messages (4xx)
export const UPDATES_CLIENT_ERROR_MSGS = {
  DRAFT: (
    <>
      We're having trouble saving the update - please{' '}
      <InlineExternalLink
        text="log a bug"
        link="https://admin.staff.monash/feedback"
      />
    </>
  ),
  PUBLISH: (
    <>
      We're having trouble publishing the update - please{' '}
      <InlineExternalLink
        text="log a bug"
        link="https://admin.staff.monash/feedback"
      />
    </>
  ),
  ARCHIVE: (
    <>
      We're having trouble archiving the update - please{' '}
      <InlineExternalLink
        text="log a bug"
        link="https://admin.staff.monash/feedback"
      />
    </>
  ),
  DELETE: (
    <>
      We're having trouble deleting the update - please{' '}
      <InlineExternalLink
        text="log a bug"
        link="https://admin.staff.monash/feedback"
      />
    </>
  ),
};

// success messages
export const UPDATES_SUCCESS_MSGS = {
  SAVE: 'Update has been saved as a draft',
  SCHEDULED: 'Update has been scheduled',
  PUBLISH: 'Update has been published',
  DELETE: 'Update has been removed',
  ARCHIVE: 'Update has been archived',
};
