import { Icon, Accordion, RadioButton } from '@monash/portal-react';
import { getIcon } from '@monash/portal-frontend-common';
import React, { useContext } from 'react';
import c from './link-form.module.scss';
import SlideOutForm from '../../common/slide-out/SlideOutForm';
import { SlideOutContext } from '../../common/slide-out/SlideOutWrapper';
import { Controller, useForm } from 'react-hook-form';
import { transformLinkDataForForm } from './utils/transform-link-data-for-form';
import PreviewLinkCard from '../preview-link-card/PreviewLinkCard';
import { LINK_USER } from '../all-links/constants';

const LinkForm = ({ existing, deleteLink, submit, cancel }) => {
  const { halfWidth } = useContext(SlideOutContext);

  const initialValues = transformLinkDataForForm(existing);

  const methods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: initialValues,
  });

  const { control, setValue, watch } = methods;

  const name = watch('name');
  const description = watch('description');
  const app = watch('app');
  const icon = app ? getIcon(app) : null;

  // form props
  const formHeaderActions = [
    {
      icon: Icon.Trash,
      onClick: deleteLink,
      disabled: !existing,
      label: 'Delete link',
    },
  ];

  const formSubmitActions = [
    {
      label: 'Publish',
    },
    {
      label: 'Save as draft',
    },
    {
      label: 'Schedule publish',
    },
  ];

  return (
    <SlideOutForm
      width={halfWidth}
      heading={name}
      headerActions={formHeaderActions}
      submitActions={formSubmitActions}
    >
      <Accordion
        useCustomStyles
        className={c.customAccordion}
        renderHiddenContent
        items={[
          {
            title: 'User',
            content: (
              <div>
                <div className={c.userInputGroup}>
                  <Controller
                    control={control}
                    name="user"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <RadioButton
                        label="Student"
                        onClick={() => setValue('user', LINK_USER.STUDENT)}
                        on={value === LINK_USER.STUDENT}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="user"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <RadioButton
                        label="Staff"
                        onClick={() => setValue('user', LINK_USER.STAFF)}
                        on={value === LINK_USER.STAFF}
                      />
                    )}
                  />
                </div>
                <div className={c.previewContainer}>
                  <PreviewLinkCard
                    icon={icon}
                    name={name}
                    description={description}
                  />
                </div>
              </div>
            ),
          },
          { title: 'Display', content: 'Display content here' },
          { title: 'Categories', content: 'Categories content here' },
          {
            title: 'Additional search optimisation',
            content: 'ASO content here',
          },
        ]}
        hasExpandAll={false}
      />
    </SlideOutForm>
  );
};

export default LinkForm;
