const buildGenericFilterList = (filtersList, filterOption) => {
  // if filter option is not already included in the list, push it to the list
  if (
    !filtersList.some((filter) => {
      return filter.text === filterOption;
    })
  ) {
    filtersList.push({
      text: filterOption,
      value: filterOption,
    });
  }
};

const buildCategoriesFilterList = (categoriesFiltersList, categories) => {
  categories.forEach((category) => {
    buildGenericFilterList(categoriesFiltersList, category);
  });
};

export const getFilterLists = (linksData) => {
  const filterLists = {
    type: [],
    app: [],
    categories: [],
  };

  // build each filter list by looping over links data once
  linksData.forEach((indexed) => {
    const link = indexed.entityContent;
    const type = link.type;
    const app = link.app;
    const categories = link.categories;

    // type
    buildGenericFilterList(filterLists.type, type);

    // app
    buildGenericFilterList(filterLists.app, app);

    // categories
    buildCategoriesFilterList(filterLists.categories, categories);
  });

  Object.values(filterLists).forEach((list) => {
    // sort each list alphabetically
    list.sort((a, b) => {
      if (a.text < b.text) {
        return -1;
      }
      if (a.text > b.text) {
        return 1;
      }
      return 0;
    });

    // add 'not selected' to front of array
    list.unshift({
      text: 'Not selected',
      value: null,
    });
  });

  return filterLists;
};
