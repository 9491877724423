import { isValid } from 'date-fns';
import { INLINE_ERROR_MSGS } from '../../../constants/inline-error-messages';
import { getTimestampFromDateAndTimeInputs } from '../../utilities/get-timestamp-from-date-and-time-inputs';

export const getErrorMessage = ({ publishDate, publishTime }) => {
  const publishTimestamp = getTimestampFromDateAndTimeInputs(
    publishDate,
    publishTime
  );

  if (!publishDate && !publishTime) {
    return INLINE_ERROR_MSGS.DATE_AND_TIME;
  }

  if (!isValid(publishDate)) {
    return INLINE_ERROR_MSGS.DATE;
  }

  if (!publishTime) {
    return INLINE_ERROR_MSGS.TIME;
  }

  if (publishTimestamp && publishTimestamp < Date.now()) {
    return INLINE_ERROR_MSGS.DATE_AND_TIME_SCHEDULED_BEFORE_CURRENT;
  }
};
