import { CITIZENSHIP_TYPES } from '../../../constants/citizenships';

export const modifyCodesForCitizenship = (groups) => {
  return groups?.map((group) => {
    if (group.citizenship === CITIZENSHIP_TYPES.DOMESTIC) {
      return { ...group, code: `${group.code}-D` };
    }
    if (group.citizenship === CITIZENSHIP_TYPES.INTERNATIONAL) {
      return { ...group, code: `${group.code}-I` };
    }
    return group;
  });
};

export const getUserGroupFromCode = (suffixedCode, userGroups) => {
  const foundGroup = userGroups.find(
    (userGroup) => userGroup.code === suffixedCode
  );

  return {
    ...foundGroup,
    code: removeCodeSuffix(foundGroup.code),
  };
};

// Removes the suffix from OR group codes
// e.g. OR00018-D -> OR00018
export const removeCodeSuffix = (code) => {
  return code?.split('-')[0];
};

export const getSelectedOrGroups = (orGroups) => {
  const list = [];

  for (const code in orGroups) {
    const value = orGroups[code];
    if (value) {
      list.push(code);
    }
  }

  return list;
};
