import { AuthContext, MenuContext } from '@monash/portal-frontend-common';
import {
  Icon,
  Route,
  Sidebar,
  SidebarContainer,
  SidebarItem,
  SidebarSection,
  SidebarItemSwitch,
} from '@monash/portal-react';
import React, { useContext } from 'react';
import SlideOutWrapper from '../common/slide-out/SlideOutWrapper';
import AllLinks from './all-links/AllLinksAdmin';
import ACCESS_ROLES from '../../constants/user-roles';

const Links = ({ root }) => {
  const { menu, handleMenu, closeMenu, menuRef, size } =
    useContext(MenuContext);

  const authCtx = useContext(AuthContext);

  const hasLinksAccess = [
    ACCESS_ROLES.ADMIN,
    ACCESS_ROLES.SUPPORT_DEVELOPER,
    ACCESS_ROLES.LINKS_ADMIN,
    ACCESS_ROLES.LINKS_DRAFT,
  ].some((x) => authCtx?.user?.roles?.includes(x));

  const withSidebar = (content) => (
    <SidebarContainer onLeft={true}>
      <Sidebar
        title="Admin"
        ifMenu={menu}
        toggleMenu={handleMenu}
        closeMenu={closeMenu}
        size={size}
        menuRef={menuRef}
      >
        <SidebarSection title="Managing for">
          <SidebarItemSwitch text="Links" linkTo="/" />
        </SidebarSection>
        <SidebarSection title="Boards">
          {hasLinksAccess && (
            <SidebarItem
              linkTo="apps/links/links"
              text="Links"
              icon={<Icon.Link />}
              onMenu={closeMenu}
            />
          )}
        </SidebarSection>
      </Sidebar>
      <SlideOutWrapper>{content}</SlideOutWrapper>
    </SidebarContainer>
  );

  return (
    <>
      <Route to={`${root}/links`} exact>
        {withSidebar(<AllLinks />)}
      </Route>
    </>
  );
};

export default Links;
