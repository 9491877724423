import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { getTimestampFromDateAndTimeInputs } from '../../../../utilities/get-timestamp-from-date-and-time-inputs';
import { INLINE_ERROR_MSGS } from '../../../../../constants/inline-error-messages';
import DateAndTime from '../../../../common/date-and-time/DateAndTime';
import { isValid } from 'date-fns';

const NotificationEndDateAndTime = ({ disabled, required = false }) => {
  const {
    watch,
    trigger,
    formState: { errors, touchedFields },
  } = useFormContext();

  const startDate = watch('startDate');
  const startTime = watch('startTime');
  const endDate = watch('endDate');
  const endTime = watch('endTime');

  const validateEndDate = (value) => {
    if ((!value && required) || !isValid(value)) return INLINE_ERROR_MSGS.DATE;

    if (endTime) {
      const endDateTimeStamp = getTimestampFromDateAndTimeInputs(
        value,
        endTime
      );
      if (endDateTimeStamp < Date.now())
        return INLINE_ERROR_MSGS.DATE_AND_TIME_END_BEFORE_CURRENT;

      const startDateTimeStamp = getTimestampFromDateAndTimeInputs(
        startDate,
        startTime
      );
      if (endDateTimeStamp < startDateTimeStamp)
        return INLINE_ERROR_MSGS.DATE_AND_TIME_END_BEFORE_START;
    }
  };

  const getErrorMessage = () => {
    if (!endDate && !endTime) {
      return INLINE_ERROR_MSGS.DATE_AND_TIME;
    } else if (errors.endDate) {
      return errors.endDate?.message;
    } else if (errors.endTime) {
      return INLINE_ERROR_MSGS.TIME;
    }
  };

  useEffect(() => {
    // select day is set when textField onBlur fires hence validation must be triggered as an effect
    if (touchedFields.endDate) trigger('endDate');
  }, [endDate, endTime]);

  return (
    <DateAndTime
      label="End date and time"
      names={{ date: 'endDate', time: 'endTime' }}
      validateDate={!disabled && validateEndDate}
      errorMessage={(errors.endDate || errors.endTime) && getErrorMessage()}
      disabled={disabled}
      required
    />
  );
};

export default NotificationEndDateAndTime;
