/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import tinycolor from 'tinycolor2';
import c from './color-picker.module.scss';

// color block

const ColorBlock = ({ color, changeColor }) => {
  return color ? (
    <div
      className={c.colorBlock}
      style={{ backgroundColor: color }}
      onClick={() => changeColor(color)}
    />
  ) : null;
};

// color picker

const ColorPicker = ({
  color = null,
  defaultColors = [
    '#00508c',
    '#1c1c1c',
    '#7fe899',
    '#cb0031',
    '#ffba00',
    '#4ac8dc',
    '#536100',
    '#ff7086',
  ],
  onChange = (e) => e,
}) => {
  const [active, setActive] = useState(false);

  // select color

  const changeColor = (value) => {
    onChange(value);
  };

  // default colors

  const renderDefaultColors = defaultColors.map((color, i) => (
    <ColorBlock key={color} {...{ color, changeColor }} />
  ));

  // history

  const [history, setHistory] = useState(
    JSON.parse(window.localStorage.getItem('colorHistory')) || []
  );

  const addToHistory = (value) => {
    if (history.indexOf(value) === -1) {
      setHistory((f) => {
        const newHistory = [value, ...f];
        newHistory.length = defaultColors.length;
        window.localStorage.setItem('colorHistory', JSON.stringify(newHistory));
        return newHistory;
      });
    }
  };

  const renderHistory = history.map((color, i) => (
    <ColorBlock key={color} {...{ color, changeColor }} />
  ));

  // custom colors

  const [customColor, setCustomColor] = useState('');

  const checkCustomColor = (e) => {
    setCustomColor(e.target.value);

    const value = `#${e.target.value}`;
    const style = new Option().style;
    style.color = value;

    if (style.color !== '') {
      changeColor(value);
      addToHistory(value);
    }
  };

  // render

  return (
    <div className={c.ColorPicker}>
      {/* color */}
      <div className={c.colorContainer}>
        <span>{color}</span>
        <div
          className={c.color}
          style={{ backgroundColor: color }}
          onClick={() => setActive(!active)}
        />
      </div>

      {/* modal */}
      {active && <div className={c.overlay} onClick={() => setActive(false)} />}
      {/* eslint-disable-next-line */}
      <div className={c.modal} active={active ? '' : null}>
        <div className={c.left}>
          {/* default colors */}
          <div className={c.defaultColors}>{renderDefaultColors}</div>

          {/* custom color history */}
          <div className={c.colorHistory}>{renderHistory}</div>
        </div>

        <div className={c.right}>
          {/* preview */}
          <div
            className={c.colorPreview}
            style={{
              backgroundColor: color,
              color: tinycolor(color).getBrightness() < 180 ? '#fff' : '#111',
            }}
          >
            {color}
          </div>

          {/* custom color */}
          <div className={c.customColor}>
            <span>#</span>
            <input
              aria-label="custom colour"
              type="text"
              onChange={checkCustomColor}
              value={customColor}
              maxLength={8}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ColorPicker;
