import classNames from 'classnames';
import c from './block-error-message.module.scss';
import { IllustrationBrokenFlower, fontStyles } from '@monash/portal-react';

const BlockErrorMessage = () => {
  return (
    <div className={c.errorContainer}>
      <IllustrationBrokenFlower />
      <div className={c.textContainer}>
        <div className={classNames(fontStyles.heading, fontStyles.md)}>
          Something went wrong
        </div>
        <div className={classNames(c.smallTextContainer, fontStyles.body)}>
          <div>We're having trouble connecting to the server right now.</div>
          <div>Please try again later.</div>
        </div>
      </div>
    </div>
  );
};

export default BlockErrorMessage;
