import { RadioButton, RadioGroup } from '@monash/portal-react';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import c from '../notification-form.module.scss';
import { getTimestampFromDateAndTimeInputs } from '../../../../utilities/get-timestamp-from-date-and-time-inputs';
import { INLINE_ERROR_MSGS } from '../../../../../constants/inline-error-messages';
import DateAndTime from '../../../../common/date-and-time/DateAndTime';
import { isValid } from 'date-fns';

const NotificationStartDateAndTime = ({ startNow, setStartNow, disabled }) => {
  const {
    watch,
    clearErrors,
    setValue,
    trigger,
    formState: { errors, touchedFields },
  } = useFormContext();
  const startDate = watch('startDate');
  const startTime = watch('startTime');

  const onClickStartNow = () => {
    setStartNow(true);
    setValue('startDate', null);
    setValue('startTime', '');
    clearErrors(['startDate', 'startTime']);
  };

  const validateStartDate = (value) => {
    if (!value || !isValid(value)) return INLINE_ERROR_MSGS.DATE;

    const timeStamp = getTimestampFromDateAndTimeInputs(value, startTime);
    if (startTime && timeStamp < Date.now())
      return INLINE_ERROR_MSGS.DATE_AND_TIME_START_BEFORE_CURRENT;
  };

  const getErrorMessage = () => {
    if (!startDate && !startTime) {
      return INLINE_ERROR_MSGS.DATE_AND_TIME;
    } else if (errors.startDate) {
      return errors.startDate?.message;
    } else if (errors.startTime) {
      return INLINE_ERROR_MSGS.TIME;
    }
  };

  useEffect(() => {
    // select day is set when textField onBlur fires hence validation must be triggered as an effect
    if (touchedFields.startDate) trigger('startDate');
    // end date validation contains logic that could be affected by changing start date and time
    if (touchedFields.endDate) trigger('endDate');
  }, [startDate, startTime]);

  return (
    <div className={c.inputGroup}>
      {disabled ? (
        <label>Start date and time</label>
      ) : (
        <RadioGroup heading="Start date and time" headingLevel="label">
          <RadioButton
            name="Start time"
            onClick={onClickStartNow}
            on={startNow}
            label="Start now"
          />
          <RadioButton
            name="Start time"
            onClick={() => setStartNow(false)}
            on={!startNow}
            label="Schedule start"
          />
        </RadioGroup>
      )}
      {!startNow && (
        <DateAndTime
          names={{ date: 'startDate', time: 'startTime' }}
          validateDate={!disabled && validateStartDate}
          errorMessage={
            (errors.startDate || errors.startTime) && getErrorMessage()
          }
          disabled={disabled}
          required
        />
      )}
    </div>
  );
};

export default NotificationStartDateAndTime;
