import React, { useContext, useEffect, useState } from 'react';
import c from '../notifications.module.scss';
import { LoadingIndicatorPlanet, Tabs } from '@monash/portal-react';
import {
  NOTIFICATION_STATUS_IDS,
  NOTIFICATION_TYPE_IDS,
} from '../../../../constants/notifications-props';
import {
  APIContext,
  capitaliseFirstWord,
} from '@monash/portal-frontend-common';
import { getNotificationStatusFromDates } from '../utils';
import { SlideOutContext } from '../../../common/slide-out/SlideOutWrapper';
import NotificationForm from '../notification-form/NotificationForm';
import ExpandableCard from '../../../common/expandable-card/ExpandableCard';
import { formatDateRange } from '../../../utilities/format-date';
import BlockErrorMessage from '../../../common/block-error-message/BlockErrorMessage';
import NewFormButton from '../../../common/new-form-button/NewFormButton';

const ALL_NOTIFICATIONS_CONTENT = {
  CRITICAL: {
    heading: 'Critical & emergency notification',
    description: 'Publish, edit, and manage emergency notification.',
  },
  NON_CRITICAL: {
    heading: 'Non-critical notification',
    description: 'Publish, edit, and manage non-critical notifications.',
  },
};

const AllNotifications = ({ type }) => {
  const { getNotifications, getUserGroups } = useContext(APIContext);
  const [notifications, setNotifications] = useState(null);
  const [notificationsError, setNotificationsError] = useState(null);
  const [allUserGroups, setAllUserGroups] = useState(null);
  const [userGroupsError, setUserGroupsError] = useState(null);
  const { slide, setSlide, open, setOpen } = useContext(SlideOutContext);
  // tabs (filters)
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const tabs = [
    'All',
    ...NOTIFICATION_STATUS_IDS.map((status) => capitaliseFirstWord(status)),
  ];
  const selectedStatus = NOTIFICATION_STATUS_IDS[selectedTabIndex - 1];

  // filtered notification data
  const displayData =
    selectedTabIndex === 0
      ? notifications
      : notifications.filter(
          (notification) =>
            getNotificationStatusFromDates(
              notification.startDate,
              notification.endDate
            ) === selectedStatus
        );

  // sorted notification data (by start date)
  const displayDataSortedByStartDate = displayData?.sort(
    (a, b) => b.startDate - a.startDate
  );

  const openSlideForm = (data) => {
    setSlide({
      content: (
        <NotificationForm
          existingNotification={data}
          setNotifications={setNotifications}
          allUserGroups={allUserGroups}
          type={type}
        />
      ),
      data,
    });
    setOpen(true);
  };

  // get notifications
  useEffect(() => {
    getNotifications(NOTIFICATION_TYPE_IDS[type])
      .then((r) => {
        setNotifications(r);
      })
      .catch((error) => {
        console.warn(
          '[getNotifications]: api call error, failed to get notifications.',
          error
        );
        setNotificationsError(true);
      });
  }, []);

  // get all user groups
  useEffect(() => {
    getUserGroups(NOTIFICATION_TYPE_IDS[type])
      .then((r) => {
        setAllUserGroups(r);
      })
      .catch((error) => {
        console.warn(
          '[getUserGroups]: api call error, failed to get userGroups.',
          error
        );
        setUserGroupsError(true);
      });
  }, []);

  const renderContent = () => {
    // error => render "something went wrong"
    if (notificationsError || userGroupsError) {
      return <BlockErrorMessage />;
    }

    // no error, but no display data yet => render loading spinner
    if (!displayDataSortedByStartDate) {
      return <LoadingIndicatorPlanet />;
    }

    // no error, and display data exists => render regular content
    return (
      <>
        <div className={c.tabs}>
          <Tabs
            tabs={tabs}
            selected={selectedTabIndex}
            onChange={(i) => {
              setSelectedTabIndex(i);
            }}
          />
        </div>
        <div className={c.notificationList}>
          {displayDataSortedByStartDate?.map((notification) => {
            const id = notification.id;
            const startDate = notification.startDate;
            const endDate = notification.endDate;
            const label = formatDateRange(startDate, endDate);

            return (
              <ExpandableCard
                title={notification.title}
                expandedContent={notification.description}
                status={getNotificationStatusFromDates(startDate, endDate)}
                label={label}
                onClick={() => openSlideForm(notification)}
                selected={open && slide?.data?.id === id}
                key={id}
              />
            );
          })}
        </div>
      </>
    );
  };

  return (
    <div className={c.container}>
      <div className={c.allNotifications}>
        <div className={c.header}>
          <div className={c.headingGroup}>
            <h1>{ALL_NOTIFICATIONS_CONTENT[type].heading}</h1>
            <p>{ALL_NOTIFICATIONS_CONTENT[type].description}</p>
          </div>
          <NewFormButton
            isMinimised={open}
            onClick={() => {
              openSlideForm(null);
            }}
            aria-label="Add new notification"
            disabled={notificationsError || userGroupsError}
          >
            New
          </NewFormButton>
        </div>

        {renderContent()}
      </div>
    </div>
  );
};

export default AllNotifications;
