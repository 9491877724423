import c from './preview-link-card.module.scss';
import { Card, fontStyles } from '@monash/portal-react';
import classNames from 'classnames';

const PreviewLinkCard = ({ icon, name, description }) => {
  const isEmpty = !icon && !name && !description;

  return (
    <Card className={c.card}>
      {isEmpty ? (
        <>
          <div className={classNames(c.skeleton, c.icon)} />
          <div className={classNames(c.skeleton, c.name)} />
          <div className={classNames(c.skeleton, c.desc)} />
        </>
      ) : (
        <>
          <div>{icon}</div>
          <h3 className={classNames(fontStyles.heading, fontStyles.sm)}>
            {name}
          </h3>
          <p className={classNames(fontStyles.body, c.description)}>
            {description}
          </p>
        </>
      )}
    </Card>
  );
};

export default PreviewLinkCard;
