import { Alert, Button, Icon, IconButton, Tag } from '@monash/portal-react';
import React, { useContext, useState } from 'react';
import c from './slide-out.module.scss';
import { SlideOutContext } from './SlideOutWrapper';
import ActionModal from './action-modal/ActionModal';
import { getTagText } from '../../utilities/get-tag-text';
import { TAG_COLORS } from '../../../constants/tag-colors';

const SlideOutForm = ({
  name,
  width,
  heading,
  headerActions,
  submitActions = [],
  children,
  isDirty,
  onClose,
  status,
  publishDate,
  submitError,
}) => {
  const { setOpen } = useContext(SlideOutContext);
  const [actionModalProps, setActionModalProps] = useState(null);
  const [isActionModalOpen, setIsActionModalOpen] = useState(null);
  const tagText = getTagText({ status, publishDate });

  const openActionModal = (props) => {
    setActionModalProps(props);
    setIsActionModalOpen(true);
  };

  // submitActions
  const hasSubmitActions = submitActions?.length > 0;
  const [primarySubmitAction, secondarySubmitAction, tertiarySubmitAction] =
    submitActions;

  return (
    <>
      <ActionModal
        isOpen={isActionModalOpen}
        setIsOpen={setIsActionModalOpen}
        props={actionModalProps}
        setProps={setActionModalProps}
      />
      <form
        className={c.slideOutForm}
        style={{ width: width ? `${width}px` : '100%' }}
      >
        <div className={c.header}>
          <h3>{heading || 'Untitled'}</h3>

          <div className={c.actions}>
            {status && (
              <Tag
                className={c.statusTag}
                color={TAG_COLORS[status]}
                text={tagText}
              />
            )}

            {Array.isArray(headerActions) &&
              headerActions.length !== 0 &&
              headerActions?.map((action) => (
                <IconButton
                  icon={action.icon}
                  color={!action.disabled && 'var(--card-text-color)'}
                  onClick={
                    action.modalProps
                      ? () => openActionModal(action.modalProps)
                      : action.onClick
                  }
                  key={action.label}
                  disabled={action.disabled}
                  aria-label={action.label}
                />
              ))}
            <IconButton
              icon={Icon.X}
              color="var(--card-text-color)"
              onClick={() => {
                const close = () => {
                  setOpen(false);
                  onClose?.();
                };

                if (isDirty) {
                  openActionModal({
                    title: `Close ${name}`,
                    content: 'Your changes will be lost.',
                    cta: {
                      onClick: () => close(),
                      variant: 'primary',
                      label: 'Close',
                    },
                  });
                } else {
                  close();
                }
              }}
              aria-label="Close form"
            />
          </div>
        </div>

        <div className={c.content}>{children}</div>

        {hasSubmitActions ? (
          <div className={c.submitActionsContainer}>
            {/* TODO: handle loading states */}
            {submitError && <Alert type="error">{submitError}</Alert>}
            <div className={c.submitActions}>
              <Button
                variant="primary"
                size="medium"
                disabled={primarySubmitAction.disabled}
                loading={primarySubmitAction.loading}
                onClick={primarySubmitAction.onClick}
              >
                {primarySubmitAction.label}
              </Button>

              <div className={c.nonPrimaryActions}>
                {secondarySubmitAction && (
                  <Button
                    variant="text"
                    size="small"
                    disabled={secondarySubmitAction.disabled}
                    loading={secondarySubmitAction.loading}
                    onClick={
                      secondarySubmitAction.modalProps
                        ? () =>
                            openActionModal(secondarySubmitAction.modalProps)
                        : secondarySubmitAction.onClick
                    }
                  >
                    {secondarySubmitAction.label}
                  </Button>
                )}
                {tertiarySubmitAction && (
                  <Button
                    variant="text"
                    size="small"
                    disabled={tertiarySubmitAction.disabled}
                    loading={tertiarySubmitAction.loading}
                    onClick={
                      tertiarySubmitAction.modalProps
                        ? () => openActionModal(tertiarySubmitAction.modalProps)
                        : tertiarySubmitAction.onClick
                    }
                  >
                    {tertiarySubmitAction.label}
                  </Button>
                )}
              </div>
            </div>
          </div>
        ) : null}
      </form>
    </>
  );
};

export default SlideOutForm;
