import { format, isValid } from 'date-fns';

export const formatDateRange = (startDate, endDate) => {
  const formatDate = (date) => {
    if (!isValid(date)) {
      console.warn('formatDateRange: invalid date given');
      return null;
    }
    return format(date, 'dd/MM/yyyy h:mmaaa');
  };

  return (
    startDate && endDate && `${formatDate(startDate)} - ${formatDate(endDate)}`
  );
};
