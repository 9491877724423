// Users
export const LINK_USER = {
  STUDENT: 'student',
  STAFF: 'staff',
};

export const USER_FILTERS = [
  {
    text: 'Not selected',
    value: null,
  },
  {
    text: 'Student',
    value: LINK_USER.STUDENT,
  },
  {
    text: 'Staff',
    value: LINK_USER.STAFF,
  },
];

// Statuses
export const LINK_STATUS = {
  DRAFT: 'draft',
  MODIFIED: 'modified',
  SCHEDULED: 'scheduled',
  PUBLISHED: 'published',
};

export const STATUS_FILTERS = [
  {
    text: 'Not selected',
    value: null,
  },
  {
    text: 'Draft',
    value: LINK_STATUS.DRAFT,
  },
  {
    text: 'Modified',
    value: LINK_STATUS.MODIFIED,
  },
  {
    text: 'Scheduled',
    value: LINK_STATUS.SCHEDULED,
  },
  {
    text: 'Published',
    value: LINK_STATUS.PUBLISHED,
  },
];
