import { getTimestampFromDateAndTimeInputs } from '../../../../utilities/get-timestamp-from-date-and-time-inputs';
import { SUBMIT_TYPES } from '../constants';

export const calculatePublishDate = ({ publishDate, publishTime, type }) => {
  if (type === SUBMIT_TYPES.DRAFT) {
    return null;
  }

  return [SUBMIT_TYPES.SCHEDULED, SUBMIT_TYPES.SAVE].includes(type)
    ? getTimestampFromDateAndTimeInputs(publishDate, publishTime)
    : Date.now();
};
