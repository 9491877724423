import { DELETE_TYPES, SUBMIT_TYPES, UPDATES_SUCCESS_MSGS } from '../constants';

export const getDeleteSuccessMsg = (type) => {
  return type === DELETE_TYPES.ARCHIVE
    ? UPDATES_SUCCESS_MSGS.ARCHIVE
    : UPDATES_SUCCESS_MSGS.DELETE;
};

export const getSubmitSuccessMsg = (type) => {
  switch (type) {
    case SUBMIT_TYPES.DRAFT:
    case SUBMIT_TYPES.REMOVE_SCHEDULED:
      return UPDATES_SUCCESS_MSGS.SAVE;
    case SUBMIT_TYPES.SCHEDULED:
      return UPDATES_SUCCESS_MSGS.SCHEDULED;
    case SUBMIT_TYPES.PUBLISH:
      return UPDATES_SUCCESS_MSGS.PUBLISH;
    default:
      console.warn('UpdateForm: invalid type given for submit success message');
  }
};
