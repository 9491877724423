import { Alert, Button, Modal, ModalSection } from '@monash/portal-react';
import DateAndTime from '../date-and-time/DateAndTime';
import c from './schedule-publish-modal.module.scss';
import InlineErrorMessage from '../inline-error-message/InlineErrorMessage';
import { useEffect } from 'react';
import { getErrorMessage } from './get-error-message';
import { UPDATE_STATUSES } from '../../home/updates/constants';
import { SUBMIT_TYPES } from '../../home/updates/update-form/constants';
import { useFormContext } from 'react-hook-form';

const SchedulePublishModal = ({
  isOpen,
  setIsOpen,
  onPublish: onPublishProp,
  onRemoveSchedule,
  publishDate,
  publishTime,
  touchedFields,
  errors,
  submitError,
  trigger,
  status,
  loadingType,
}) => {
  const { setError } = useFormContext();

  // Since publishTime is the field with the validate function
  // publishDate needs to be triggered manually
  useEffect(() => {
    if (touchedFields.publishDate || touchedFields.publishTime)
      trigger('publishTime');
  }, [publishDate]);

  const onPublish = async () => {
    // errors refers to react hook form errors. submitError refers to error state when the publish call fails
    const hasErrors = Boolean(Object.keys(errors).length) || submitError;

    // manually handle the case where a user gives empty inputs
    if (!(publishDate && publishTime)) {
      setError('publishTime', {
        type: 'required',
        message: getErrorMessage({
          publishDate,
          publishTime,
        }),
      });
      return;
    }

    if (!hasErrors) {
      onPublishProp();
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={async () => {
        setIsOpen(false);
        await trigger();
      }}
    >
      <ModalSection title="Schedule publish update">
        <DateAndTime
          label="Select date and time"
          names={{ date: 'publishDate', time: 'publishTime' }}
          disabled={Boolean(loadingType)}
          validateTime={() => {
            if (isOpen) {
              const err = getErrorMessage({
                publishDate,
                publishTime,
              });

              return err;
            }
          }}
        />

        {errors.publishTime && (
          <InlineErrorMessage
            className={c.error}
            message={errors.publishTime.message}
          />
        )}
      </ModalSection>
      <div className={c.actionsContainer}>
        {submitError && <Alert type="error">{submitError}</Alert>}

        <div className={c.actionsButtons}>
          <span>
            {status === UPDATE_STATUSES.SCHEDULED && (
              <Button
                className={c.removeSchedule}
                onClick={onRemoveSchedule}
                size="small"
                variant="text"
                loading={loadingType === SUBMIT_TYPES.REMOVE_SCHEDULED}
                disabled={Boolean(loadingType)}
              >
                Remove schedule
              </Button>
            )}
          </span>

          <Button
            onClick={onPublish}
            size="large"
            loading={loadingType === SUBMIT_TYPES.SCHEDULED}
            disabled={Boolean(loadingType)}
          >
            Schedule publish
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default SchedulePublishModal;
