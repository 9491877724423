import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { useContext, useEffect, useState } from 'react';
import * as Sentry from '@sentry/browser';
import {
  Accordion,
  Button,
  Icon,
  RemovableTag,
  TextField,
  toggleExpandedIndexes,
} from '@monash/portal-react';
import SlideOutForm from '../../../common/slide-out/SlideOutForm';
import PreviewUpdateCard from '../update-card/PreviewUpdateCard';
import { SlideOutContext } from '../../../common/slide-out/SlideOutWrapper';
import NotificationLink from '../../../admin/notifications/notification-form/notification-link/NotificationLink';
import { NOTIFICATION_EMPTY_LINK } from '../../../../constants/notifications-props';
import UserGroupsSelector from '../../../common/user-groups-selector/UserGroupsSelector';
import {
  modifyCodesForCitizenship,
  removeCodeSuffix,
} from '../../../common/user-groups-selector/utils';
import DateAndTime from '../../../common/date-and-time/DateAndTime';
import {
  getErroredAccordionIndexes,
  validateStartAndEndDateOnSubmit,
} from './utils';
import InlineErrorMessage from '../../../common/inline-error-message/InlineErrorMessage';
import SchedulePublishModal from '../../../common/schedule-publish-modal/SchedulePublishModal';
import {
  AccessibilityContext,
  APIContext,
  AuthContext,
} from '@monash/portal-frontend-common';
import ACCESS_ROLES from '../../../../constants/user-roles';
import { isAuthorized } from '../../../utilities/is-authorized';
import { transformFormDataForSubmission } from './utils/transform-form-data-for-submission';
import { transformUpdateDataForForm } from './utils/transform-update-data-for-form';
import { UPDATE_STATUSES } from '../constants';
import HistoryTimeline from '../../../common/history-timeline/HistoryTimeline';
import c from './update-form.module.scss';
import {
  DELETE_TYPES,
  SUBMIT_TYPES,
  UPDATES_CLIENT_ERROR_MSGS,
  UPDATES_SERVER_ERROR_MSGS,
} from './constants';
import { getErrorFromApiErrorMsg } from '../../../admin/notifications/notification-form/utils';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import {
  getDeleteSuccessMsg,
  getSubmitSuccessMsg,
} from './utils/get-success-msg';
import { INLINE_ERROR_MSGS } from '../../../../constants/inline-error-messages';

const UpdateForm = ({
  existingUpdate: existingUpdateProp,
  userGroups,
  setUpdatesList,
  getUpdates,
}) => {
  const [existingUpdate, setExistingUpdate] = useState(existingUpdateProp);
  const [openAccordionIndexes, setOpenAccordionIndexes] = useState([]);
  const [loadingType, setLoadingType] = useState(null);
  const { halfWidth, open, setOpen } = useContext(SlideOutContext);
  const authCtx = useContext(AuthContext);
  const { resetAppLiveMsgs } = useContext(AccessibilityContext);
  const { addSnackbar } = useSnackbar();

  const [showOrGroupModal, setShowOrGroupModal] = useState(false);
  const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false);

  const { createUpdate, editUpdate, deleteUpdate } = useContext(APIContext);

  const [inlineError, setInlineError] = useState(null);

  const initialValues = transformUpdateDataForForm(existingUpdate);

  const methods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: initialValues,
  });

  const isExisting = Boolean(existingUpdate);
  const isFormDisabled = Boolean(loadingType) || !open;
  const isPublished = existingUpdate?.status === UPDATE_STATUSES.PUBLISHED;
  const isScheduled = existingUpdate?.status === UPDATE_STATUSES.SCHEDULED;

  const availableUserGroups = userGroups.filter((group) => group.available);
  const userGroupsWithSuffixes = modifyCodesForCitizenship(userGroups);

  const {
    control,
    watch,
    trigger,
    register,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors, isValid, isDirty, touchedFields, isSubmitted },
  } = methods;

  register('userGroups', {
    validate: (value) =>
      (Array.isArray(value) && value.length !== 0) ||
      INLINE_ERROR_MSGS.USER_GROUP,
  });

  const { append: appendLink, remove: removeLink } = useFieldArray({
    name: 'links',
    control,
  });

  const title = watch('title');
  const description = watch('description');
  const links = watch('links');
  const orGroups = watch('userGroups');
  const publishDate = watch('publishDate');
  const publishTime = watch('publishTime');
  const startDate = watch('startDate');
  const startTime = watch('startTime');
  const endDate = watch('endDate');
  const endTime = watch('endTime');

  const onClose = () => {
    setInlineError(null);
  };

  const addSnackbarMsg = (msg, msgType) => {
    resetAppLiveMsgs();
    addSnackbar({
      message: msg,
      type: msgType,
    });
  };

  const onDeleteUpdate = async ({ type } = {}) => {
    try {
      const response = await deleteUpdate(existingUpdate.id);
      const newUpdates = await getUpdates();

      setUpdatesList(newUpdates);
      setOpen(false);

      // success messaging
      const deleteSuccessMsg = getDeleteSuccessMsg(type);
      addSnackbarMsg(deleteSuccessMsg, 'success');

      return response;
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);

      // throwing an error instead of handling as it is done in action modal
      throw error;
    }
  };

  const formHeaderActions = [
    {
      icon: Icon.ClockReverse,
      onClick: () => {},
      label: 'View history',
      disabled: !existingUpdate || isFormDisabled,
      modalProps: {
        title: 'History',
        content: <HistoryTimeline history={existingUpdate?.history} />,
      },
    },
    isPublished
      ? {
          icon: Icon.Box,
          label: 'Archive update',
          disabled: !isExisting,
          modalProps: {
            title: 'Archive update',
            content:
              'Archive will remove the update from student app. Are you sure you want to archive this update?',
            cta: {
              async: true,
              onClick: () => onDeleteUpdate({ type: DELETE_TYPES.ARCHIVE }),
              onError: (error) =>
                getErrorFromApiErrorMsg(
                  error.message,
                  UPDATES_SERVER_ERROR_MSGS.ARCHIVE,
                  UPDATES_CLIENT_ERROR_MSGS.ARCHIVE
                ),
              variant: 'delete',
              label: 'Archive',
            },
          },
        }
      : {
          icon: Icon.Trash,
          label: 'Delete update',
          disabled: !isExisting || isFormDisabled,
          modalProps: {
            title: 'Delete update',
            content: 'Are you sure you want to delete this update?',
            cta: {
              async: true,
              onClick: () => onDeleteUpdate({ type: DELETE_TYPES.DELETE }),
              onError: (error) =>
                getErrorFromApiErrorMsg(
                  error.message,
                  UPDATES_SERVER_ERROR_MSGS.DELETE,
                  UPDATES_CLIENT_ERROR_MSGS.DELETE
                ),
              variant: 'delete',
              label: 'Delete',
            },
          },
        },
  ];

  const validateActionablePeriod = () => {
    return validateStartAndEndDateOnSubmit({
      startDate,
      startTime,
      endDate,
      endTime,
    });
  };

  const removeUserGroup = (code) => {
    const newSelectedUserGroups = orGroups.filter((group) => group !== code);
    setValue('userGroups', newSelectedUserGroups, {
      shouldValidate: true,
    });
  };

  const validateForm = async ({ type } = {}) => {
    if (type === SUBMIT_TYPES.DRAFT) {
      return { isValid: true };
    }

    if (!isValid) {
      await trigger();
      const erroredAccordionIndexes = getErroredAccordionIndexes({
        errors,
      });
      setOpenAccordionIndexes(erroredAccordionIndexes);
    }

    return { isValid };
  };

  const openSchedulePublishModal = async () => {
    // This is needed to change the form to using reValidateMode
    handleSubmit(() => {})();

    const { isValid } = await validateForm();
    if (isValid) {
      setIsScheduleModalOpen(true);
    }
  };

  const onRemoveSchedule = async () => {
    await onSubmit({ type: SUBMIT_TYPES.REMOVE_SCHEDULED });
    setIsScheduleModalOpen(false);
    setExistingUpdate({ ...existingUpdate, status: UPDATE_STATUSES.DRAFT });
  };

  const onSubmit = async ({ type }) => {
    // This is needed so the form starts using reValidateMode
    handleSubmit(() => {})();

    const { isValid } = await validateForm({ type });
    const values = getValues();

    if (isValid || type === SUBMIT_TYPES.DRAFT) {
      setLoadingType(type);

      const body = transformFormDataForSubmission({
        values,
        existingUpdate,
        userGroupsWithSuffixes,
        type,
      });

      try {
        if (existingUpdate) {
          await editUpdate(existingUpdate.id, body);
        } else {
          await createUpdate(body);
        }

        const newUpdates = await getUpdates();
        setUpdatesList(newUpdates);

        if (type !== SUBMIT_TYPES.REMOVE_SCHEDULED) {
          setOpen(false);
        }

        if (type === SUBMIT_TYPES.SCHEDULED) {
          setIsScheduleModalOpen(false);
        }

        // success messaging
        const submitSuccessMsg = getSubmitSuccessMsg(type);
        addSnackbarMsg(submitSuccessMsg, 'success');
      } catch (error) {
        console.error(error);
        Sentry.captureException(error);

        const submitServerErrorMsg =
          type === SUBMIT_TYPES.DRAFT
            ? UPDATES_SERVER_ERROR_MSGS.DRAFT
            : UPDATES_SERVER_ERROR_MSGS.PUBLISH;

        const submitClientErrorMsg =
          type === SUBMIT_TYPES.DRAFT
            ? UPDATES_CLIENT_ERROR_MSGS.DRAFT
            : UPDATES_CLIENT_ERROR_MSGS.PUBLISH;

        setInlineError(
          getErrorFromApiErrorMsg(
            error.message,
            submitServerErrorMsg,
            submitClientErrorMsg
          )
        );
      } finally {
        setLoadingType(null);
      }
    }
  };

  const { SUPPORT_DEVELOPER, UPDATES_ADMIN, UPDATES_DRAFT } = ACCESS_ROLES;

  const formSubmitActions = [
    {
      label: 'Publish',
      onClick: () => onSubmit({ type: SUBMIT_TYPES.PUBLISH }),
      requiredRoles: [SUPPORT_DEVELOPER, UPDATES_ADMIN],
      loading: loadingType === SUBMIT_TYPES.PUBLISH,
      disabled: isFormDisabled,
      showWhenPublished: true,
    },
    ...[
      isScheduled
        ? {
            label: 'Save',
            onClick: () => onSubmit({ type: SUBMIT_TYPES.SAVE }),
            requiredRoles: [SUPPORT_DEVELOPER, UPDATES_ADMIN],
            loading: loadingType === SUBMIT_TYPES.SAVE,
            disabled: isFormDisabled,
          }
        : {
            label: 'Save as draft',
            onClick: () => onSubmit({ type: SUBMIT_TYPES.DRAFT }),
            requiredRoles: [SUPPORT_DEVELOPER, UPDATES_ADMIN, UPDATES_DRAFT],
            loading: loadingType === SUBMIT_TYPES.DRAFT,
            disabled: isFormDisabled,
          },
    ],
    {
      label: 'Schedule publish',
      onClick: openSchedulePublishModal,
      requiredRoles: [SUPPORT_DEVELOPER, UPDATES_ADMIN],
      disabled: isFormDisabled,
    },
  ]
    .filter((action) =>
      isAuthorized(authCtx?.user?.roles, action.requiredRoles)
    )
    .filter((action) => {
      const isAvailable = !isExisting || !isPublished;
      return isAvailable || action.showWhenPublished;
    });

  // Since endTime is the only field with the validate function
  // publishDate needs to be triggered manually
  useEffect(() => {
    if (isSubmitted) {
      trigger('endTime');
    }
  }, [startDate, startTime, endDate]);

  return (
    <FormProvider {...methods}>
      <SlideOutForm
        name="update"
        width={halfWidth}
        heading={title}
        headerActions={formHeaderActions}
        submitActions={formSubmitActions}
        isDirty={isDirty}
        status={existingUpdate?.status}
        publishDate={existingUpdate?.publishDate}
        submitError={inlineError}
        onClose={onClose}
      >
        <PreviewUpdateCard
          title={title}
          description={description}
          links={links}
        />

        <Accordion
          className={c.customAccordion}
          renderHiddenContent
          expandedIndexes={openAccordionIndexes}
          onClick={(index) => {
            setOpenAccordionIndexes(
              toggleExpandedIndexes(index, openAccordionIndexes)
            );
          }}
          items={[
            {
              title: 'Display',
              content: (
                <div className={c.updateForm}>
                  <div className={c.inputGroup}>
                    <label>Title</label>
                    <Controller
                      control={control}
                      name="title"
                      rules={{
                        required: INLINE_ERROR_MSGS.TITLE,
                      }}
                      render={({ field: { onBlur, onChange, value } }) => (
                        <TextField
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          placeholder="Title"
                          error={() => errors.title}
                          errorMsg={errors.title?.message}
                          disabled={Boolean(loadingType)}
                        />
                      )}
                    />
                  </div>

                  <div className={c.inputGroup}>
                    <label>Description</label>
                    <Controller
                      control={control}
                      name="description"
                      rules={{
                        required: INLINE_ERROR_MSGS.DESCRIPTION,
                      }}
                      render={({ field: { onBlur, onChange, value } }) => (
                        <TextField
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          placeholder="Description"
                          error={() => errors.description}
                          errorMsg={errors.description?.message}
                          disabled={Boolean(loadingType)}
                          multiline
                        />
                      )}
                    />
                  </div>

                  <div className={c.inputGroup}>
                    <label>Link (Optional)</label>
                    <div className={c.links}>
                      {links.length !== 0 &&
                        links.map((link, i) => (
                          <NotificationLink
                            key={link.id}
                            index={i}
                            removeLink={removeLink}
                          />
                        ))}
                    </div>

                    <Button
                      variant="text"
                      mode="card"
                      size="small"
                      onClick={() => appendLink(NOTIFICATION_EMPTY_LINK)}
                      disabled={Boolean(loadingType)}
                    >
                      Add link
                    </Button>
                  </div>
                </div>
              ),
            },
            {
              title: 'Actionable period (optional)',
              content: (
                <div className={c.updateForm}>
                  <DateAndTime
                    label="Start date and time"
                    names={{ date: 'startDate', time: 'startTime' }}
                    disabled={Boolean(loadingType)}
                  />
                  <DateAndTime
                    label="End date and time"
                    names={{ date: 'endDate', time: 'endTime' }}
                    errorMessage={errors.endTime?.message}
                    validateTime={validateActionablePeriod}
                    disabled={Boolean(loadingType)}
                  />
                </div>
              ),
            },
            {
              title: 'User group',
              content: (
                <div className={c.updateForm}>
                  <div className={c.tags}>
                    {orGroups.map((group) => {
                      const name = userGroupsWithSuffixes.find(
                        (orGroup) => group === orGroup.code
                      )?.name;

                      return (
                        <RemovableTag
                          key={group}
                          text={`${removeCodeSuffix(group)} - ${name}`}
                          size="large"
                          color="greyBlue"
                          onRemove={() => removeUserGroup(group)}
                          disabled={Boolean(loadingType)}
                        />
                      );
                    })}
                  </div>

                  <div className={c.inputGroup}>
                    <Button
                      variant="text"
                      mode="card"
                      size="small"
                      onClick={() => {
                        setShowOrGroupModal(true);
                      }}
                    >
                      Add user group
                    </Button>

                    {errors.userGroups && (
                      <InlineErrorMessage message={errors.userGroups.message} />
                    )}
                  </div>

                  <UserGroupsSelector
                    control={control}
                    showModal={showOrGroupModal}
                    setShowModal={setShowOrGroupModal}
                    setValue={setValue}
                    selectedUserGroups={orGroups}
                    availableUserGroups={availableUserGroups}
                    showCountryTabs={true}
                    name="userGroups"
                  />

                  <SchedulePublishModal
                    isOpen={isScheduleModalOpen}
                    setIsOpen={setIsScheduleModalOpen}
                    publishDate={publishDate}
                    publishTime={publishTime}
                    touchedFields={touchedFields}
                    onPublish={async () => {
                      await onSubmit({ type: SUBMIT_TYPES.SCHEDULED });
                    }}
                    trigger={trigger}
                    status={existingUpdate?.status}
                    errors={errors}
                    submitError={inlineError}
                    onRemoveSchedule={onRemoveSchedule}
                    loadingType={loadingType}
                  />
                </div>
              ),
            },
          ]}
        />
      </SlideOutForm>
    </FormProvider>
  );
};

export default UpdateForm;
