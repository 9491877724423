export const LINKS_EMPTY_VALUES = {
  name: '',
  user: '',
  type: 'Feature',
  app: '',
  description: '',
  longDescription: '',
  userGroups: [],
  categories: [],
  url: '',
  tags: [],
  disabled: false,
};
