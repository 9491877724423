import React, { useContext, useEffect, useState } from 'react';
import {
  useFieldArray,
  Controller,
  useForm,
  FormProvider,
} from 'react-hook-form';
import { Button, Icon, TextField } from '@monash/portal-react';
import c from './notification-form.module.scss';
import { SlideOutContext } from '../../../common/slide-out/SlideOutWrapper';
import NotificationLink from './notification-link/NotificationLink';
import NotificationApps from './notification-apps/NotificationApps';
import {
  NOTIFICATION_CLIENT_ERROR_MSGS,
  NOTIFICATION_EMPTY_LINK,
  NOTIFICATION_SERVER_ERROR_MSGS,
  NOTIFICATION_SUCCESS_MSGS,
  NOTIFICATION_TYPE_EMPTY_FORMS,
  NOTIFICATION_TYPE_IDS,
} from '../../../../constants/notifications-props';
import SlideOutForm from '../../../common/slide-out/SlideOutForm';
import NotificationEndDateAndTime from './notification-date-and-time/NotificationEndDateAndTime';
import NotificationStartDateAndTime from './notification-date-and-time/NotificationStartDateAndTime';
import {
  checkHasUnavailableUserGroups,
  getErrorFromApiErrorMsg,
  transformFormDataForSubmission,
  transformNotificationDataForForm,
} from './utils';
import {
  APIContext,
  AccessibilityContext,
} from '@monash/portal-frontend-common';
import isEqual from 'lodash.isequal';
import HistoryTimeline from '../../../common/history-timeline/HistoryTimeline';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { INLINE_ERROR_MSGS } from '../../../../constants/inline-error-messages';

const NotificationForm = ({
  existingNotification,
  type,
  setNotifications,
  allUserGroups,
}) => {
  const { halfWidth, open, setOpen } = useContext(SlideOutContext);
  const [startNow, setStartNow] = useState(!existingNotification?.startDate);
  const [submitError, setSubmitError] = useState(null);
  const { createNotification, deleteNotification, updateNotification } =
    useContext(APIContext);
  const { resetAppLiveMsgs } = useContext(AccessibilityContext);
  const { addSnackbar } = useSnackbar();
  const [isPublishLoading, setIsPublishLoading] = useState(false);

  // notification status
  const currentDate = Date.now();
  const isActive =
    existingNotification?.startDate < currentDate &&
    existingNotification?.endDate > currentDate;
  const isExpired = existingNotification?.endDate < currentDate;
  const isScheduled = existingNotification?.startDate > currentDate;
  const isPublishing = startNow || isActive;

  // notification edit right
  const hasUnavailableUserGroups = checkHasUnavailableUserGroups(
    existingNotification,
    allUserGroups
  );
  const isDisabled =
    isExpired || hasUnavailableUserGroups || isPublishLoading || !open;

  const initialValues =
    transformNotificationDataForForm(existingNotification) ||
    NOTIFICATION_TYPE_EMPTY_FORMS[type];

  const methods = useForm({
    mode: 'onBlur',
    defaultValues: initialValues,
  });

  const {
    reset,
    control,
    watch,
    trigger,
    getValues,
    formState: { errors, isValid },
  } = methods;

  // form values
  const title = watch('title');
  const values = getValues();

  // form states
  const isDirty = !isEqual(values, initialValues);

  // links
  const {
    fields: links,
    append: appendLink,
    remove: removeLink,
  } = useFieldArray({
    name: 'links',
    control,
  });

  // messaging

  const submitSuccessMsg = isPublishing
    ? NOTIFICATION_SUCCESS_MSGS.PUBLISH
    : NOTIFICATION_SUCCESS_MSGS.SAVE;

  const submitServerErrorMsg = isPublishing
    ? NOTIFICATION_SERVER_ERROR_MSGS.PUBLISH
    : NOTIFICATION_SERVER_ERROR_MSGS.SAVE;

  const submitClientErrorMsg = isPublishing
    ? NOTIFICATION_CLIENT_ERROR_MSGS.PUBLISH
    : NOTIFICATION_CLIENT_ERROR_MSGS.SAVE;

  const addSnackbarMsg = (msg, msgType) => {
    resetAppLiveMsgs();
    addSnackbar({
      message: msg,
      type: msgType,
    });
  };

  const submitNewNotification = (e) => {
    e.preventDefault();
    setSubmitError(null);
    if (isValid) {
      setIsPublishLoading(true);
      const transformedDataForSubmission = transformFormDataForSubmission(
        NOTIFICATION_TYPE_IDS[type],
        values,
        startNow
      );
      createNotification(transformedDataForSubmission)
        .then((r) => {
          setNotifications((f) => [...f, r]);
          setOpen(false);
          addSnackbarMsg(submitSuccessMsg, 'success');
        })
        .catch((error) => {
          console.warn(
            '[createNotification]: api call error, failed to create notification.',
            error
          );
          setSubmitError(
            getErrorFromApiErrorMsg(
              error.message,
              submitServerErrorMsg,
              submitClientErrorMsg
            )
          );
        })
        .finally(() => {
          setIsPublishLoading(false);
        });
    } else {
      trigger();
    }
  };

  const submitNotification = async ({ endNow, inModal }) => {
    // if form has not been changed, close panel
    setSubmitError(null);
    if (!isDirty && !endNow) {
      setOpen(false);
      // if form has been changed, update notification
    } else if (isValid) {
      try {
        setIsPublishLoading(true);
        const transformedDataForSubmission = transformFormDataForSubmission(
          NOTIFICATION_TYPE_IDS[type],
          values,
          startNow,
          endNow
        );
        const response = await updateNotification(transformedDataForSubmission);
        if (response) {
          setNotifications((f) => {
            const notificationIndex = f?.findIndex(
              (item) => item.id === response.id
            );
            const newNotifications = [...f];
            newNotifications[notificationIndex] = response;
            return newNotifications;
          });
          setOpen(false);
          addSnackbarMsg(
            endNow ? NOTIFICATION_SUCCESS_MSGS.END_NOW : submitSuccessMsg,
            'success'
          );
          return response;
        }
      } catch (error) {
        console.warn(
          '[updateNotification]: api call error, failed to update notification.',
          error
        );

        // if submit action is done in a modal, throw an error
        if (inModal) {
          throw error;
        } else {
          // else, set error and display error message on form
          setSubmitError(
            getErrorFromApiErrorMsg(
              error.message,
              submitServerErrorMsg,
              submitClientErrorMsg
            )
          );
        }
      } finally {
        setIsPublishLoading(false);
      }
    } else {
      trigger();
    }
  };

  const onDeleteNotification = async () => {
    try {
      const response = await deleteNotification(existingNotification?.id);
      if (response) {
        setOpen(false);
        setNotifications((f) => {
          const notificationIndex = f?.findIndex(
            (item) => item.id === response.deleted
          );
          const newNotifications = [...f];
          newNotifications.splice(notificationIndex, 1);
          return newNotifications;
        });
        addSnackbarMsg(NOTIFICATION_SUCCESS_MSGS.DELETE, 'success');
        return response;
      }
    } catch (error) {
      console.warn(
        '[deleteNotification]: api call error, failed to delete notification.',
        error
      );
      // throwing an error instead of handling as it is done in action modal
      throw error;
    }
  };

  // slide form props
  const formHeaderActions = [
    {
      icon: Icon.ClockReverse,
      label: 'View history',
      disabled: !existingNotification,
      modalProps: {
        title: `History`,
        content: <HistoryTimeline history={existingNotification?.history} />,
      },
    },
    {
      icon: Icon.Trash,
      label: 'Delete notification',
      disabled: !(existingNotification && isScheduled),
      modalProps: {
        title: `Delete notification`,
        content: 'Are you sure you want to delete this notification?',
        cta: {
          async: true,
          onClick: onDeleteNotification,
          onError: (error) =>
            getErrorFromApiErrorMsg(
              error.message,
              NOTIFICATION_SERVER_ERROR_MSGS.DELETE,
              NOTIFICATION_CLIENT_ERROR_MSGS.DELETE
            ),
          variant: 'delete',
          label: 'Delete',
        },
      },
    },
  ];

  const formSubmitActions = [
    {
      onClick: existingNotification
        ? () =>
            submitNotification({
              endNow: false,
              inModal: false,
            })
        : submitNewNotification,
      label: isPublishing ? 'Publish' : 'Save',
      disabled: isDisabled,
    },
    isActive && {
      label: 'End now',
      disabled: !isValid || isDisabled,
      modalProps: {
        title: `End notification`,
        content: 'Are you sure you want to end this notification early?',
        cta: {
          async: true,
          onClick: () => submitNotification({ endNow: true, inModal: true }),
          onError: (error) =>
            getErrorFromApiErrorMsg(
              error.message,
              NOTIFICATION_SERVER_ERROR_MSGS.END_NOW,
              NOTIFICATION_CLIENT_ERROR_MSGS.END_NOW
            ),
          variant: 'delete',
          label: 'End',
        },
      },
    },
  ];

  // reset form values and local state
  useEffect(() => {
    reset(initialValues);
    setStartNow(!existingNotification?.startDate);
    setSubmitError(null);
  }, [open, existingNotification]);

  return (
    <FormProvider {...methods}>
      <SlideOutForm
        name="notification"
        width={halfWidth}
        heading={title}
        headerActions={formHeaderActions}
        submitActions={formSubmitActions}
        isDirty={isDirty}
        submitError={submitError}
      >
        <div className={c.notificationForm}>
          <div className={c.inputGroup}>
            <label>Title</label>
            <Controller
              control={control}
              name="title"
              rules={{
                required: INLINE_ERROR_MSGS.TITLE,
              }}
              render={({ field: { onBlur, onChange, value } }) => (
                <TextField
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  placeholder="Untitled"
                  error={() => errors.title}
                  errorMsg={errors.title?.message}
                  disabled={isDisabled}
                />
              )}
            />
          </div>
          <NotificationStartDateAndTime
            startNow={startNow}
            setStartNow={setStartNow}
            disabled={isDisabled || isActive}
          />
          <NotificationEndDateAndTime disabled={isDisabled} required />
          <div className={c.inputGroup}>
            <label>Apps to publish</label>
            <NotificationApps
              allUserGroups={allUserGroups}
              disabled={isDisabled}
            />
          </div>
          <div className={c.inputGroup}>
            <label>Description</label>
            <Controller
              control={control}
              name="description"
              rules={{
                required: INLINE_ERROR_MSGS.DESCRIPTION,
              }}
              render={({ field: { onBlur, onChange, value } }) => (
                <TextField
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  placeholder="Description"
                  multiline
                  height="12rem"
                  error={() => errors.description}
                  errorMsg={errors.description?.message}
                  disabled={isDisabled}
                />
              )}
            />
          </div>
          <div className={c.inputGroup}>
            <label>Link (Optional)</label>
            <div className={c.links}>
              {links.length !== 0 &&
                links.map((link, i) => (
                  <NotificationLink
                    key={link.id}
                    index={i}
                    removeLink={removeLink}
                    disabled={isDisabled}
                  />
                ))}
            </div>
            <Button
              variant="text"
              mode="card"
              size="small"
              onClick={() => appendLink(NOTIFICATION_EMPTY_LINK)}
              disabled={isDisabled}
            >
              Add link
            </Button>
          </div>
          {NOTIFICATION_TYPE_IDS[type] === 'critical' && (
            <div className={c.inputGroup}>
              <label>Authorised by</label>
              <Controller
                control={control}
                name="authorisedBy"
                rules={{
                  required: 'Authorised by must not be empty',
                }}
                render={({ field: { onBlur, onChange, value } }) => (
                  <TextField
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    placeholder="Name"
                    error={() => errors.authorisedBy}
                    errorMsg={errors.authorisedBy?.message}
                    disabled={isDisabled}
                  />
                )}
              />
            </div>
          )}
        </div>
      </SlideOutForm>
    </FormProvider>
  );
};

export default NotificationForm;
