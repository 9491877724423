import {
  Card,
  Logo,
  InlineExternalLink,
  fontStyles as fs,
} from '@monash/portal-react';
import c from './preview-update-card.module.scss';
import classNames from 'classnames';

const PreviewUpdateCard = ({ title, description, links }) => {
  const titleClasses = classNames(c.title, fs.heading, fs.md, {
    [c.visible]: title,
  });

  const descriptionClasses = classNames(c.description, {
    [c.visible]: description,
  });

  return (
    <div>
      <Card className={c.updateCard}>
        <div className={c.topSection}>
          <div className={c.logoWrapper}>
            <Logo.Shield height={24} /> Monash University
          </div>
        </div>

        <div className={c.textWrapper}>
          <h3 className={titleClasses}>{title || <>&nbsp;</>}</h3>
          <p className={descriptionClasses}>{description || <>&nbsp;</>}</p>
        </div>

        <div className={c.links}>
          {links.map(({ label, url, id }) => {
            const textLabel = label || url;

            return (
              textLabel && (
                <InlineExternalLink
                  link={url}
                  text={textLabel}
                  className={c.link}
                  key={id}
                />
              )
            );
          })}
        </div>
      </Card>
    </div>
  );
};

export default PreviewUpdateCard;
