import { getTimestampFromDateAndTimeInputs } from '../../../utilities/get-timestamp-from-date-and-time-inputs';
import { INLINE_ERROR_MSGS } from '../../../../constants/inline-error-messages';
import { isValid } from 'date-fns';

export const validateStartAndEndDateOnSubmit = ({
  startDate,
  startTime,
  endDate,
  endTime,
}) => {
  const values = [startDate, startTime, endDate, endTime];
  const hasSomeValues = values.some(Boolean);
  const hasAllValues = values.every(Boolean);

  const startDateTime = getTimestampFromDateAndTimeInputs(startDate, startTime);
  const endDateTime = getTimestampFromDateAndTimeInputs(endDate, endTime);

  // only perform validation if at least one field has a value
  if (!hasSomeValues) return;

  // invalid date format
  if ((startDate && !isValid(startDate)) || (endDate && !isValid(endDate))) {
    return INLINE_ERROR_MSGS.DATE;
  }

  // end date & time but no start date & time
  if (endDate && endTime && !startDate && !startTime) {
    return INLINE_ERROR_MSGS.DATE_AND_TIME_START;
  }

  // missing fields
  if (!hasAllValues) {
    return INLINE_ERROR_MSGS.DATE_AND_TIME_START_AND_END;
  }

  // end date & time before start date & time
  if (endDateTime < startDateTime) {
    return INLINE_ERROR_MSGS.DATE_AND_TIME_END_BEFORE_START;
  }
};

export const getErroredAccordionIndexes = ({ errors }) => {
  const erroredIndexes = [];
  if (errors.title || errors.description || errors.links) {
    erroredIndexes.push(0);
  }
  if (errors.endTime) {
    erroredIndexes.push(1);
  }
  if (errors.userGroups) {
    erroredIndexes.push(2);
  }
  return erroredIndexes;
};
