import { capitaliseFirstWord } from '@monash/portal-frontend-common';
import { format } from 'date-fns';
import { UPDATE_STATUSES } from '../home/updates/constants';

export const getTagText = ({ status, publishDate }) => {
  if (status === UPDATE_STATUSES.SCHEDULED && publishDate) {
    return `Scheduled: ${format(publishDate, 'dd/MM/yyyy h:mmaaa')}`;
  }
  return capitaliseFirstWord(status);
};
