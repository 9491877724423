import { Button, Icon, IconButton } from '@monash/portal-react';

const NewFormButton = ({ isMinimised = false, children, ...buttonProps }) => {
  return isMinimised ? (
    <IconButton icon={Icon.Plus} variant="primary" {...buttonProps} />
  ) : (
    <Button
      icon={<Icon.Plus />}
      iconPosition="left"
      variant="primary"
      size="medium"
      {...buttonProps}
    >
      {children}
    </Button>
  );
};

export default NewFormButton;
