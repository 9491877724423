export const COUNTRIES = {
  AUSTRALIA: 'Australia',
  MALAYSIA: 'Malaysia',
  INDONESIA: 'Indonesia',
};

export const COUNTRIES_LIST = [
  COUNTRIES.AUSTRALIA,
  COUNTRIES.INDONESIA,
  COUNTRIES.MALAYSIA,
];
