import { format, isValid } from 'date-fns';

export const getTimestampFromDateAndTimeInputs = (dateInput, timeInput) => {
  if (!isValid(dateInput) || !timeInput) return null;

  const formattedDateInput = format(new Date(dateInput), 'yyyy-MM-dd');
  const dateTime = formattedDateInput + ' ' + timeInput;
  const timestamp = new Date(dateTime).getTime();

  return timestamp;
};
