import React, { useState } from 'react';
import {
  Alert,
  Button,
  Modal,
  ModalAction,
  ModalSection,
  useMedia,
} from '@monash/portal-react';
import c from './action-modal.module.scss';

const ActionModal = ({ isOpen, setIsOpen, props, setProps }) => {
  const size = useMedia();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const ctaButtonSize = size === 'S' ? 'small' : 'medium';

  const resetAction = () => {
    setIsOpen(false);
    setProps(null);
  };

  const confirmAsyncAction = async () => {
    setLoading(true);
    try {
      const response = await props?.cta?.onClick();
      if (response) {
        setLoading(false);
        resetAction();
      }
    } catch (error) {
      setLoading(false);
      setError(props?.cta?.onError(error) || error);
    }
  };

  const confirmAction = () => {
    props?.cta?.onClick();
    resetAction();
  };

  const onClose = () => {
    setError(null);
    setIsOpen(false);
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      size={size}
      ariaLabel={props?.title}
      stopPropagationOnDefaultKeyDown={true}
    >
      <ModalSection title={props?.title} titleTabIndex={null}>
        <div className={c.content}>{props?.content}</div>
      </ModalSection>
      {props?.cta ? (
        <ModalAction position="center">
          <div className={c.modalAction}>
            {error && <Alert type="error">{error}</Alert>}
            <div className={c.modalButtons}>
              <Button variant="text" size={ctaButtonSize} onClick={onClose}>
                Cancel
              </Button>
              <Button
                variant={props?.cta?.variant}
                size={ctaButtonSize}
                loading={loading}
                onClick={props?.cta?.async ? confirmAsyncAction : confirmAction}
              >
                {props?.cta?.label}
              </Button>
            </div>
          </div>
        </ModalAction>
      ) : null}
    </Modal>
  );
};

export default ActionModal;
